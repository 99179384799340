import Button from '@/components/UI/button';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

import Header from '@/components/header';
import ImageComponent from '@/components/UI/image';

import useWindowSize from '@/hooks/useWindowSize';

import Cupls from '@/assets/images/cupls.png';

import style from './404.module.scss';
import HeadData from '@/components/headData';
import CustomLink from '@/components/UI/link';

const Page404 = () => {
  const { t } = useTranslation();
  const { windowWidth } = useWindowSize();
  const [width, setWidth] = useState(633);

  useEffect(() => {
    if (windowWidth <= 1180) {
      setWidth(433);
    }
    if (windowWidth <= 980) {
      setWidth(400);
    }
    if (windowWidth <= 720) {
      setWidth(300);
    }
    if (windowWidth > 1180) {
      setWidth(633);
    }
  }, [windowWidth]);

  return (
    <section className={style.content}>
      <HeadData title={'404.title'} />
      <Header register />
      <div className={style.wrapper}>
        <h1 className={style.title}>404</h1>
        <span className={style.describe}>{t('404.describe')}</span>
        <CustomLink
          href='/'
          target={false}
          style={style.back}
          text='404.button'
        />
      </div>
      <ImageComponent
        image={Cupls}
        alt='cupls'
        width={width}
        otherStyle={style.image}
      />
    </section>
  );
};

export default Page404;
